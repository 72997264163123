var searchRequest = null

function refreshTable(showAll) {
  if (searchRequest != null) {
    searchRequest.abort()
  }
  let criteria = $("#health_provider_criteria").val()
  let active = $("#health_provider_active").val()
  let types = []
  $('input[name="health_provider_types"]:checked').each((index, input) => types.push(input.value))

  searchRequest = $.ajax({
    url: "/health_providers/search",
    data: {
      criteria: criteria,
      active: active,
      types: types,
      show_all: showAll
    },
    success: function (data) {
      $("#health-providers").empty()
      $("#health-providers").append(data)
      $("#health-providers").css("display","inline")
      $("#health_providers_result").DataTable()
      $("#show-all-providers").on("click", function(event) {
        refreshTable(true)
      })
    }
  })
}

$(function() {
  $("#health_provider_criteria").on("keyup", function (event) {
    refreshTable(false)
  })

  $("#health_provider_active").on("change", function (event) {
    refreshTable(false)
  })

  $("#health_provider_search_types input[type=\"checkbox\"]").on("change", function (event) {
    refreshTable(false)
  })
})
