function updateLists() {
    let type = $("#projectable_type").val()
    let criteria = $("#projectable_criteria").val().toLowerCase()

    if (!type) {
        $("#not-added-project-members #health-providers").show()
        $("#not-added-project-members #clusters").show()
        searchList("health-providers", criteria)
        searchList("clusters", criteria)
    } else if (type == "cluster") {
        $("#not-added-project-members #health-providers").hide()
        $("#not-added-project-members #clusters").show()
        searchList("clusters", criteria)
    } else {
        $("#not-added-project-members #health-providers").show()
        $("#not-added-project-members #clusters").hide()
        searchList("health-providers", criteria, type)
    }
}

function searchList(listName, criteria, shownType = null) {
    $("#not-added-project-members #" + listName + " tr input").parent().parent().each(function (index, element) {
        let tr = $(element)
        let id = tr.find("td:nth-child(2)").html()
        let name = tr.find("td:nth-child(3)").html().toLowerCase()
        let providerType = tr.find("input").data("type")
        let isMatch = id.includes(criteria) || name.includes(criteria)

        if (listName != "clusters") {
            let providerTypeName = tr.find("td:nth-child(4)").html().toLowerCase()
            isMatch = isMatch || providerTypeName.includes(criteria)
        }

        if (!shownType && isMatch) {
            tr.show()
        } else if(shownType && shownType == providerType && isMatch) {
            tr.show()
        } else {
            tr.hide()
        }
    })
}

function moveProjectMember(event) {
    let input = $(event.currentTarget)
    let row = input.parent().parent().remove()
    let isBeingAdded = input.is(":checked")
    let type = input.data("type")
    let newRow = buildRow(input, row, isBeingAdded, type)

    if (isBeingAdded) {
        $("#added-project-members tbody").append(newRow)
    } else {
        if (type != "cluster") {
            $("#not-added-project-members #health-providers tbody").append(newRow)
        } else {
            $("#not-added-project-members #clusters tbody").append(newRow)
        }
    }

    input.on("change", moveProjectMember)
}

function buildRow(input, row, isBeingAdded, type) {
    while (row.children().length > 1) {
        row.find("td:last-child").remove()
    }

    let id = input.data("id")
    let name = input.data("name")
    let providerTypeName = input.data("provider-type-name")
    let completedMilestones = input.data("completed-milestones")

    if (isBeingAdded) {
        row.append("<td>" + name + "</td>")
        row.append("<td>" + completedMilestones + "</td>")
    } else {
        row.append("<td>" + id + "</td>")
        row.append("<td>" + name + "</td>")
        if (type != "cluster") {
            row.append("<td>" + providerTypeName + "</td>")
        }
    }

    return row
}

function addAllSearched(event) {
    event.preventDefault()
    $("#not-added-project-members tbody tr:visible").each((index, row) => {
        $(row).find("input").prop("checked", true).triggerHandler("change")
    })
}

$(function () {
    $("#projectable_type").on("change", updateLists)
    $("#projectable_criteria").on("keyup", updateLists)
    $("#added-project-members input[type=checkbox], #not-added-project-members input[type=checkbox]").on("change", moveProjectMember)
    $("#add-all").on("click", addAllSearched)
})
